/**
 * @generated SignedSource<<c219d5e5b1cd6fd241dc3478f71a294d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableTraitCell_collection$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useTraitValue_collection">;
  readonly " $fragmentType": "PortfolioTableTraitCell_collection";
};
export type PortfolioTableTraitCell_collection$key = {
  readonly " $data"?: PortfolioTableTraitCell_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableTraitCell_collection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableTraitCell_collection",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTraitValue_collection"
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "b888411cf19baf3223db4cd73589b4ca";

export default node;
