import { fromUnixTime, intlFormat, isSameDay, startOfYear } from "date-fns"
import { graphql, useFragment } from "react-relay"
import { useTranslate } from "@/hooks/useTranslate"
import { useTraitValue_collection$key } from "@/lib/graphql/__generated__/useTraitValue_collection.graphql"
import { useTraitValue_trait$key } from "@/lib/graphql/__generated__/useTraitValue_trait.graphql"
import { getBrowseCollectionAssetsUrl } from "@/lib/helpers/collection"
import { calculatePercentages, display } from "@/lib/helpers/numberUtils"
import { snakeCaseToCapitalCase } from "@/lib/helpers/stringUtils"
import { stringifyQueryParams } from "@/lib/helpers/urls"

type TraitValue = {
  traitType: string
  displayValue: string | null
  displayPercentage: string | null
  collectionTraitUrl: string | undefined
}

const renderDate = (rawDate: Date | number): string => {
  const date = typeof rawDate === "number" ? fromUnixTime(+rawDate) : rawDate
  const isSameYear = isSameDay(date, startOfYear(date))
  return intlFormat(
    date,
    isSameYear
      ? {
          weekday: "long",
          month: "long",
          day: "numeric",
          year: "numeric",
        }
      : { month: "long", year: "numeric" },
  )
}

export const useTraitValue = (
  traitKey: useTraitValue_trait$key,
  collectionKey: useTraitValue_collection$key,
): TraitValue => {
  const t = useTranslate("hooks")
  const trait = useFragment(
    graphql`
      fragment useTraitValue_trait on TraitType {
        traitType
        traitCount
        displayType
        floatValue
        intValue
        dateValue
        maxValue
        value
      }
    `,
    traitKey,
  )

  const collection = useFragment(
    graphql`
      fragment useTraitValue_collection on CollectionType {
        statsV2 {
          totalSupply
        }
        numericTraits {
          key
          value {
            max
          }
        }
        ...collection_url
      }
    `,
    collectionKey,
  )

  const {
    traitType,
    traitCount,
    displayType,
    floatValue,
    intValue,
    dateValue,
    maxValue,
    value,
  } = trait

  const {
    statsV2: { totalSupply },
  } = collection

  const traitMax =
    maxValue ??
    collection.numericTraits.find(trait => trait.key === traitType)?.value.max

  const numericValue = floatValue ?? (intValue !== null ? +intValue : null)

  const displayValue = (() => {
    if (value !== null) {
      return snakeCaseToCapitalCase(value)
    }
    if (displayType === "DATE") {
      const rawDateValue = dateValue ?? numericValue
      return rawDateValue !== null ? renderDate(rawDateValue) : null
    }
    if (numericValue === null) {
      return null
    }
    if (displayType === "BOOST_PERCENTAGE") {
      return `${numericValue}%`
    }
    if (displayType === "BOOST_NUMBER") {
      return `${numericValue}`
    }
    return `${display(numericValue)}${traitMax ? `/${display(traitMax)}` : ""}`
  })()

  const displayPercentage = (() => {
    if (value === null) {
      return null
    }
    if (!traitCount || !totalSupply) {
      return t("traitValue.new", "New")
    }
    const percentage = calculatePercentages(traitCount, totalSupply)
    if (percentage >= 1) {
      return `${Math.round(percentage)}%`
    }
    if (percentage >= 0.01) {
      return `${percentage.toFixed(2)}%`
    }
    return t(
      "traitValue.oneOf",
      "1 of {{count}}",
      { count: display(traitCount) },
      { forceString: true },
    )
  })()

  const collectionTraitUrl =
    value !== null || numericValue !== null
      ? `${getBrowseCollectionAssetsUrl(collection)}${stringifyQueryParams({
          search: {
            numericTraits:
              numericValue !== null
                ? [
                    {
                      name: traitType,
                      ranges: [{ min: numericValue, max: numericValue }],
                    },
                  ]
                : undefined,
            stringTraits:
              value !== null
                ? [{ name: traitType, values: [value] }]
                : undefined,
            sortAscending: true,
            sortBy: "UNIT_PRICE",
          },
        })}`
      : undefined

  return {
    traitType: snakeCaseToCapitalCase(traitType),
    displayValue,
    displayPercentage,
    collectionTraitUrl,
  }
}
