import { Text } from "@opensea/ui-kit"
import styled from "styled-components"
import { Table } from "@/design-system/Table"

export const TableHeaderText = styled(Text.Body).attrs({
  className: "text-inherit",
  size: "small",
})`
  white-space: nowrap;
`

export const StyledTableCell = styled(Table.Cell)`
  background-color: transparent;
  min-height: 57.5px;
  padding-bottom: 8px;
  padding-top: 8px;
`
