import React from "react"
import { useInterval, useUpdate } from "react-use"
import { useFromNowShort } from "@/lib/helpers/datetime"

type Props = {
  interval?: number
  renderFormattedTimestamp?: () => string
  fromDate?: Date
}

export const LiveTimestamp = ({
  interval = 1000,
  renderFormattedTimestamp,
  fromDate,
}: Props) => {
  const renderFromNowShort = useFromNowShort()
  const update = useUpdate()
  useInterval(update, interval)
  return (
    <>
      {renderFormattedTimestamp
        ? renderFormattedTimestamp()
        : renderFromNowShort(fromDate || null)}
    </>
  )
}
