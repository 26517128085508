import React from "react"
import { Icon, Skeleton, Text } from "@opensea/ui-kit"
import { Flex, FlexProps } from "@/design-system/Flex"
import { IconButton } from "@/design-system/IconButton"
import { MAX_TRAITS_TO_DISPLAY_COUNT } from "@/features/account/components/PortfolioTable/constants"
import { useTranslate } from "@/hooks/useTranslate"

export type PaginationArrowsProps = FlexProps & {
  currentPage: number
  totalItemCount: number
  disabledNextButton?: boolean
  handleLoadPreviousPage: () => void
  handleLoadNextPage: () => void
}

const ARROW_BUTTON_HEIGHT = "48px"

export const PaginationArrows = ({
  currentPage,
  disabledNextButton,
  totalItemCount,
  handleLoadPreviousPage,
  handleLoadNextPage,
  ...rest
}: PaginationArrowsProps) => {
  const t = useTranslate("common")

  const endItemCount = Math.min(
    currentPage * MAX_TRAITS_TO_DISPLAY_COUNT,
    totalItemCount,
  )

  const startItemCount = (currentPage - 1) * MAX_TRAITS_TO_DISPLAY_COUNT + 1

  const currentPageItemCountLabel = t(
    "pagination.pageIndicatorInfo",
    "{{startItemCount}}-{{endItemCount}} of {{totalItemCount}}",
    { startItemCount, endItemCount, totalItemCount },
  )

  const isLastPage = endItemCount >= totalItemCount

  const showPaginationArrows = totalItemCount > MAX_TRAITS_TO_DISPLAY_COUNT

  return (
    <Flex alignItems="center" {...rest} height={ARROW_BUTTON_HEIGHT}>
      <Text className="text-secondary" size="small">
        {currentPageItemCountLabel}
      </Text>
      {showPaginationArrows && (
        <Flex marginLeft="16px">
          <IconButton
            aria-label={t("previous.items", "Show previous items")}
            className="mr-2"
            disabled={currentPage <= 1}
            onClick={handleLoadPreviousPage}
          >
            <Icon value="expand_less" />
          </IconButton>

          <IconButton
            aria-label={t("next.items", "Show next items")}
            disabled={disabledNextButton || isLastPage}
            onClick={handleLoadNextPage}
          >
            <Icon value="expand_more" />
          </IconButton>
        </Flex>
      )}
    </Flex>
  )
}

export const PaginationArrowsSkeleton = () => {
  return (
    <Flex alignItems="center" minHeight="57.5px">
      <Skeleton.Line className="mr-4 w-16" />
      <Flex alignItems="center">
        <Skeleton.Circle className="mr-2 h-10 w-10" />
        <Skeleton.Circle className="h-10 w-10" />
      </Flex>
    </Flex>
  )
}
