import { useMemo } from "react"
import { TraitColumn } from "@/features/account/components/PortfolioTable/types"
import { useTranslate } from "@/hooks/useTranslate"
import { PortfolioTableTraitTableVariant } from "../components/trait-table/PortfolioTableTraitTable.react"

export type HeaderColumn = {
  column: TraitColumn
  header: string
  minWidth?: "auto" | number
  maxWidth: "auto" | number
}

const COMPACT_TRAIT_TABLE_COLUMN_LIST: TraitColumn[] = [
  "trait",
  "floorPrice",
  "lastSale",
]

export const useTraitTableColumns = (
  variant: PortfolioTableTraitTableVariant,
): HeaderColumn[] => {
  const t = useTranslate("account")

  return useMemo(() => {
    let tableColumns: HeaderColumn[] = [
      {
        column: "trait",
        header: t("portfolio.traits.headerColumn.trait", "Trait"),
        minWidth: 150,
        maxWidth: "auto",
      },
      {
        column: "listedCount",
        header: t("portfolio.traits.headerColumn.listedCount", "Listed"),
        minWidth: 100,
        maxWidth: "auto",
      },
      {
        column: "floorPrice",
        header: t("portfolio.traits.headerColumn.floorPrice", "Floor"),
        minWidth: 100,
        maxWidth: "auto",
      },
      {
        column: "lastSale",
        header: t("portfolio.traits.headerColumn.lastSale", "Last sale"),
        minWidth: 100,
        maxWidth: "auto",
      },
      {
        column: "lastSaleDate",
        header: t("portfolio.traits.headerColumn.lastSaleDate", "Last sold"),
        minWidth: 100,
        maxWidth: "auto",
      },
    ]

    if (variant === "compact") {
      tableColumns = [...tableColumns].filter(({ column }) =>
        COMPACT_TRAIT_TABLE_COLUMN_LIST.includes(column),
      )
    }

    return tableColumns
  }, [t, variant])
}
