/**
 * @generated SignedSource<<a70e1cbca1a1db8a2a6ce9c5cc8e861d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableItemCellTooltip_item$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "PortfolioTableTraitTable_asset" | "asset_url">;
  readonly " $fragmentType": "PortfolioTableItemCellTooltip_item";
};
export type PortfolioTableItemCellTooltip_item$key = {
  readonly " $data"?: PortfolioTableItemCellTooltip_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableItemCellTooltip_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableItemCellTooltip_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "showRarity",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "AssetMedia_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableTraitTable_asset"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "asset_url",
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetContractType",
              "kind": "LinkedField",
              "name": "assetContract",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tokenId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ChainType",
              "kind": "LinkedField",
              "name": "chain",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "identifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};

(node as any).hash = "5fa7bcc680fea8a6f4a76bfd8117f2ba";

export default node;
