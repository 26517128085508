import React, { Suspense } from "react"
import { Flex } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import styled from "styled-components"
import type { Placement } from "tippy.js"
import { AssetMedia } from "@/components/assets/AssetMedia"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Skeleton } from "@opensea/ui-kit"
import { Tooltip, TooltipOffset } from "@/design-system/Tooltip"
import { PortfolioTableItemCellTooltip_item$key } from "@/lib/graphql/__generated__/PortfolioTableItemCellTooltip_item.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { getAssetUrl } from "@/lib/helpers/asset"
import { PortfolioTableTraitTable } from "../trait-table/PortfolioTableTraitTable.react"

type Props = {
  item: PortfolioTableItemCellTooltip_item$key | null
  children: React.ReactNode
  disabled?: boolean
  placement?: Placement
  offset?: TooltipOffset
}

export const PortfolioTableItemCellTooltip = ({
  item: itemKey,
  disabled,
  placement,
  offset = [-16, 16],
  children,
}: Props) => {
  const item = useFragment(
    graphql`
      fragment PortfolioTableItemCellTooltip_item on ItemType {
        __typename
        ...AssetMedia_asset @arguments(showRarity: true)
        ...PortfolioTableTraitTable_asset
        ...asset_url
      }
    `,
    itemKey,
  )

  if (item?.__typename === "AssetType") {
    return (
      <Tooltip
        animation="fade-vertical"
        arrow={false}
        className="rounded-3xl"
        content={
          <Suspense fallback={<PortfolioTableItemCellTooltipSkeleton />}>
            <TooltipContainer>
              <StyledImageContainer as={Link} href={getAssetUrl(item)}>
                <StyledAssetMedia
                  asset={item}
                  rarityIndicator="solid"
                  showAssetMediaEditions
                  size={TOOLTIP_IMAGE_SIZE_PX}
                  width={TOOLTIP_IMAGE_SIZE_PX}
                />
              </StyledImageContainer>
              <PortfolioTableTraitTable asset={item} variant="compact" />
            </TooltipContainer>
          </Suspense>
        }
        contentPadding="16px"
        disabled={disabled}
        maxWidth="auto"
        offset={offset}
        touch={false}
        {...(placement ? { placement } : {})}
      >
        <Block as="span" className="w-full">
          {children}
        </Block>
      </Tooltip>
    )
  }

  return <Block as="span">{children}</Block>
}

const PortfolioTableItemCellTooltipSkeleton = () => {
  return (
    <TooltipContainer>
      <StyledImageContainer>
        <Skeleton.Block className="w-full h-full" />
      </StyledImageContainer>

      <PortfolioTableTraitTable.Skeleton variant="compact" />
    </TooltipContainer>
  )
}

const TOOLTIP_IMAGE_SIZE_PX = 200

const StyledImageContainer = styled(Block).attrs({
  borderRadius: "default",
  height: TOOLTIP_IMAGE_SIZE_PX,
  width: TOOLTIP_IMAGE_SIZE_PX,
  minWidth: TOOLTIP_IMAGE_SIZE_PX,
})`
  border: 1px solid ${props => props.theme.colors.components.border.level2};
  overflow: hidden;
  flex: 1;
`

const StyledAssetMedia = styled(AssetMedia)`
  transition: transform 0.2s;
  border-radius: 0;
  &:hover {
    transform: scale(1.12);
    transition: transform 0.4s;
  }
`

const TooltipContainer = styled(Flex)`
  align-items: flex-start;
  text-align: left;

  ${StyledImageContainer} {
    margin-right: 12px;
  }
`
