/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Breakpoint, Flex, Media } from "@opensea/ui-kit"
import { BigNumber } from "bignumber.js"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Overflow } from "@/components/common/Overflow"
import { Block, BlockProps } from "@/design-system/Block"
import { PortfolioTableItemCellTooltip } from "@/features/account/components/PortfolioTable/components/tooltips/PortfolioTableItemCellTooltip.react"
import { ItemCell_data$key } from "@/lib/graphql/__generated__/ItemCell_data.graphql"
import { getNodes, graphql } from "@/lib/graphql/graphql"
import { getItemUrl } from "@/lib/helpers/item"
import { quantityDisplay } from "@/lib/helpers/numberUtils"
import { AssetMedia, AssetMediaProps } from "../AssetMedia"

const DEFAULT_ASSET_MEDIA_SIZE = 48

type AssetCellNameProps = {
  name: string
  quantityDisplayText: string
  className?: string
}

const AssetCellName = ({
  name,
  quantityDisplayText,
  className,
}: AssetCellNameProps) => (
  <Block
    as="span"
    className={className}
    display={{ _: "none", sm: "inline-block" }}
    marginLeft="8px"
    textAlign="left"
  >
    <Overflow lines={2}>
      {name}
      {quantityDisplayText}
    </Overflow>
  </Block>
)

export type ItemCellProps = {
  item: ItemCell_data$key | null
  quantity?: BigNumber
  fullscreenBreakpoint?: Breakpoint
  renderName?: (props: AssetCellNameProps) => React.ReactNode
  link?: boolean
  onClick?: () => unknown
  overrides?: {
    AssetMedia?: Partial<AssetMediaProps>
    AssetMediaContainer?: BlockProps
  }
}

export const ItemCell = ({
  item: itemKey,
  quantity,
  fullscreenBreakpoint = "sm",
  renderName = AssetCellName,
  link = true,
  onClick,
  overrides = {},
}: ItemCellProps) => {
  const item = useFragment(
    graphql`
      fragment ItemCell_data on ItemType {
        __typename
        displayName
        ...item_url
        ...PortfolioTableItemCellTooltip_item
        ... on AssetType {
          ...AssetMedia_asset
        }
        ... on AssetBundleType {
          assetQuantities(first: 30) {
            edges {
              node {
                asset {
                  ...AssetMedia_asset
                }
                relayId
              }
            }
          }
        }
      }
    `,
    itemKey,
  )

  const isBundle = item?.__typename === "AssetBundleType"
  // We fetch 30 then slice to 2 because this assetQuantities gets merged with
  // the same field in a query for orders and there we need 30.
  const assetQuantities = getNodes(item?.assetQuantities).slice(0, 2)
  const name = item?.displayName ?? ""

  const quantityDisplayText =
    quantity && quantity.isGreaterThan(1)
      ? ` x${quantityDisplay(quantity)}`
      : ""

  const href = item ? getItemUrl(item) : undefined
  return (
    <DivContainer
      assetMediaSize={overrides.AssetMedia?.size ?? DEFAULT_ASSET_MEDIA_SIZE}
    >
      <Block
        {...(link ? { ...{ href, as: Link } } : {})}
        color="inherit"
        display="block"
        onClick={onClick}
      >
        <Flex className="items-center">
          {isBundle ? (
            <Block className="AssetCell--img-container">
              {assetQuantities.map(assetQuantity => (
                <AssetMedia
                  asset={assetQuantity.asset}
                  className="AssetCell--img-small"
                  key={assetQuantity.relayId}
                  {...overrides.AssetMedia}
                />
              ))}
            </Block>
          ) : item ? (
            <Block className="AssetCell--img-container">
              <PortfolioTableItemCellTooltip
                item={item}
                placement="bottom-start"
              >
                <AssetMedia
                  asset={item}
                  className="AssetCell--img"
                  size={DEFAULT_ASSET_MEDIA_SIZE}
                  {...overrides.AssetMedia}
                />
              </PortfolioTableItemCellTooltip>
            </Block>
          ) : null}
          <Media greaterThanOrEqual={fullscreenBreakpoint}>
            {mediaClassName =>
              renderName({
                name,
                quantityDisplayText,
                className: mediaClassName,
              })
            }
          </Media>
        </Flex>
      </Block>
    </DivContainer>
  )
}

const DivContainer = styled(Block)<{ assetMediaSize: AssetMediaProps["size"] }>`
  width: 100%;

  .AssetCell--img-container {
    border-radius: ${props => props.theme.borderRadius.default};
    border: 1px solid ${props => props.theme.colors.components.border.level2};
    flex: 0 0 ${props => props.assetMediaSize}px;
  }

  .AssetCell--img {
    flex: 0 0 ${props => props.assetMediaSize}px;
    border-radius: ${props => props.theme.borderRadius.default};
  }

  .AssetCell--img-small {
    width: 50%;
  }
`
