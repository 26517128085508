import React, { ReactNode, useCallback, useMemo } from "react"
import { CenterAligned, Skeleton, Text } from "@opensea/ui-kit"
import { range } from "lodash"
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  usePaginationFragment,
} from "react-relay"
import styled from "styled-components"
import { variant } from "styled-system"
import { EMPTY_PRICE_DISPLAY } from "@/constants/index"
import { Block } from "@/design-system/Block"
import { PriceText } from "@/design-system/PriceText"
import { Table, TableProps } from "@/design-system/Table"
import {
  StyledTableCell,
  TableHeaderText,
} from "@/features/account/components/PortfolioTable/components/styles"
import { PortfolioTableTraitCell } from "@/features/account/components/PortfolioTable/components/trait-table/PortfolioTableTraitCell.react"
import {
  MAX_COMPACT_TABLE_TRAITS_TO_DISPLAY_COUNT,
  MAX_SKELETON_TRAITS_TO_DISPLAY_COUNT,
  MAX_TRAITS_TO_DISPLAY_COUNT,
} from "@/features/account/components/PortfolioTable/constants"
import {
  HeaderColumn,
  useTraitTableColumns,
} from "@/features/account/components/PortfolioTable/hooks/useTraitTableColumns"
import {
  PaginationArrows,
  PaginationArrowsSkeleton,
} from "@/features/pagination/components/PaginationArrows.react"
import { usePaginationArrows } from "@/features/pagination/hooks/usePaginationArrows"
import { useTranslate } from "@/hooks/useTranslate"
import { PortfolioTableTraitTable_asset$key } from "@/lib/graphql/__generated__/PortfolioTableTraitTable_asset.graphql"
import { PortfolioTableTraitTable_data$key } from "@/lib/graphql/__generated__/PortfolioTableTraitTable_data.graphql"
import { PortfolioTableTraitTablePaginationQuery } from "@/lib/graphql/__generated__/PortfolioTableTraitTablePaginationQuery.graphql"
import { PortfolioTableTraitTableQuery } from "@/lib/graphql/__generated__/PortfolioTableTraitTableQuery.graphql"
import { getNodes } from "@/lib/graphql/graphql"
import { dateFromISO8601, useFromNowShort } from "@/lib/helpers/datetime"
import { bn, display } from "@/lib/helpers/numberUtils"

export type PortfolioTableTraitTableVariant = "default" | "compact"

type Props = {
  asset: PortfolioTableTraitTable_asset$key
  variant?: PortfolioTableTraitTableVariant
}

const PortfolioTableTraitTableBase = ({
  asset: assetKey,
  variant = "default",
}: Props) => {
  const t = useTranslate("account")
  const isCompactVariant = variant === "compact"

  const asset = useFragment(
    graphql`
      fragment PortfolioTableTraitTable_asset on AssetType {
        assetContract {
          address
          chain
        }
        isCurrentlyFungible
        tokenId
      }
    `,
    assetKey,
  )

  const { assetContract, isCurrentlyFungible, tokenId } = asset

  const assetWithTraits = useLazyLoadQuery<PortfolioTableTraitTableQuery>(
    graphql`
      query PortfolioTableTraitTableQuery(
        $contractAddress: AddressScalar!
        $tokenId: String!
        $chain: ChainScalar!
        $traitCount: Int!
      ) {
        ...PortfolioTableTraitTable_data
          @arguments(
            contractAddress: $contractAddress
            tokenId: $tokenId
            chain: $chain
            traitCount: $traitCount
          )
      }
    `,
    {
      tokenId,
      contractAddress: assetContract.address,
      chain: assetContract.chain,
      traitCount: MAX_TRAITS_TO_DISPLAY_COUNT,
    },
  )

  const { data, loadNext, isLoadingPrevious, isLoadingNext, loadPrevious } =
    usePaginationFragment<
      PortfolioTableTraitTablePaginationQuery,
      PortfolioTableTraitTable_data$key
    >(
      graphql`
        fragment PortfolioTableTraitTable_data on Query
        @argumentDefinitions(
          last: { type: "Int" }
          before: { type: "String" }
          after: { type: "String" }
          contractAddress: { type: "AddressScalar!" }
          tokenId: { type: "String!" }
          chain: { type: "ChainScalar!" }
          traitCount: { type: "Int!" }
        )
        @refetchable(queryName: "PortfolioTableTraitTablePaginationQuery") {
          nft(
            tokenId: $tokenId
            contractAddress: $contractAddress
            chain: $chain
          ) {
            collection {
              ...PortfolioTableTraitCell_collection
            }
            traits(
              first: $traitCount
              last: $last
              before: $before
              after: $after
              sortBy: SCARCITY
            ) @connection(key: "PortfolioTableTraitTable_traits") {
              count
              edges {
                node {
                  relayId
                  listedQuantity
                  traitCount
                  floorPrice {
                    unit
                    symbol
                  }
                  lastSale {
                    unitPriceQuantity {
                      quantity
                      asset {
                        decimals
                        symbol
                      }
                    }
                    timestamp
                  }
                  ...PortfolioTableTraitCell_trait
                }
              }
              pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
              }
            }
          }
        }
      `,
      assetWithTraits,
    )

  const renderTimestamp = useFromNowShort()

  const { nft } = data
  const allTraits = getNodes(nft.traits)
  const traitCountTotal = nft.traits.count

  const columns = useTraitTableColumns(variant)
  const { currentPage, getStartIndex, setCurrentPage } = usePaginationArrows()

  const displayedTraits = isCompactVariant
    ? allTraits.slice(0, MAX_COMPACT_TABLE_TRAITS_TO_DISPLAY_COUNT)
    : allTraits.slice(
        getStartIndex(currentPage),
        getStartIndex(currentPage) + MAX_TRAITS_TO_DISPLAY_COUNT,
      )

  const handleLoadNextPage = useCallback(() => {
    setCurrentPage(prev => prev + 1)
    loadNext(MAX_TRAITS_TO_DISPLAY_COUNT)
  }, [loadNext, setCurrentPage])

  const handleLoadPreviousPage = useCallback(() => {
    setCurrentPage(prev => prev - 1)
    loadPrevious(MAX_TRAITS_TO_DISPLAY_COUNT)
  }, [loadPrevious, setCurrentPage])

  const renderPaginationControls = () => (
    <PaginationArrows
      currentPage={currentPage}
      handleLoadNextPage={handleLoadNextPage}
      handleLoadPreviousPage={handleLoadPreviousPage}
      paddingLeft="16px"
      totalItemCount={traitCountTotal}
    />
  )

  if (isLoadingNext || isLoadingPrevious) {
    return <TableSkeleton variant={variant} />
  }

  if (!displayedTraits.length) {
    return (
      <CenterAligned
        className="h-full self-center"
        style={{
          minWidth: columns.reduce((acc, column) => {
            let columnWidth = 0

            if (typeof column.minWidth === "number") {
              columnWidth = column.minWidth
            }

            return acc + columnWidth
          }, 0),
        }}
      >
        <Text.Body className="text-secondary" size="medium" weight="semibold">
          {t(
            "portfolio.traitTable.noTraitsPlaceholder",
            "This item has no traits.",
          )}
        </Text.Body>
      </CenterAligned>
    )
  }

  return (
    <>
      <TableOutline columns={columns} variant={variant}>
        {displayedTraits.map(trait => {
          const { relayId, listedQuantity, traitCount, floorPrice, lastSale } =
            trait
          const showListedColumnData = traitCount && !isCurrentlyFungible
          return (
            <Table.Row key={relayId}>
              {columns.map(({ column }) => {
                switch (column) {
                  case "trait": {
                    return (
                      <StyledTraitTableCell $variant={variant} key={column}>
                        <PortfolioTableTraitCell
                          collection={nft.collection}
                          trait={trait}
                        />
                      </StyledTraitTableCell>
                    )
                  }
                  case "listedCount": {
                    return (
                      <StyledTraitTableCell $variant={variant} key={column}>
                        <Text.Body
                          color={showListedColumnData ? undefined : "secondary"}
                          size="small"
                        >
                          {/* TODO(MRKT-2570): Disable for 1155s until we can calc listed
              and total quantity correctly */}
                          {showListedColumnData
                            ? t(
                                "portfolio.traitTable.listedOverTotal",
                                "{{listedQuantity}} of {{totalQuantity}}",
                                {
                                  listedQuantity: display(listedQuantity),
                                  totalQuantity: display(traitCount),
                                },
                              )
                            : EMPTY_PRICE_DISPLAY}
                        </Text.Body>
                      </StyledTraitTableCell>
                    )
                  }
                  case "floorPrice": {
                    return (
                      <StyledTraitTableCell $variant={variant} key={column}>
                        <PriceText
                          subtleEmpty
                          symbol={floorPrice?.symbol}
                          unit={floorPrice?.unit}
                        />
                      </StyledTraitTableCell>
                    )
                  }
                  case "lastSale": {
                    return (
                      <StyledTraitTableCell $variant={variant} key={column}>
                        <PriceText
                          subtleEmpty
                          symbol={lastSale?.unitPriceQuantity?.asset.symbol}
                          unit={
                            lastSale?.unitPriceQuantity?.quantity
                              ? bn(
                                  lastSale.unitPriceQuantity.quantity,
                                  lastSale.unitPriceQuantity.asset.decimals,
                                )
                              : undefined
                          }
                        />
                      </StyledTraitTableCell>
                    )
                  }
                  case "lastSaleDate": {
                    return (
                      <StyledTraitTableCell $variant={variant} key={column}>
                        <Text.Body
                          color={lastSale?.timestamp ? undefined : "secondary"}
                          size="small"
                        >
                          {lastSale?.timestamp
                            ? renderTimestamp(
                                dateFromISO8601(lastSale.timestamp),
                              )
                            : EMPTY_PRICE_DISPLAY}
                        </Text.Body>
                      </StyledTraitTableCell>
                    )
                  }
                  default:
                    throw new Error("Trait column not supported")
                }
              })}
            </Table.Row>
          )
        })}
      </TableOutline>
      {variant === "default" && renderPaginationControls()}
    </>
  )
}

const TableHeader: TableProps["renderHeader"] = ({
  Header,
  header,
  index,
  removeStickyHeader,
}) => {
  return (
    <Header
      backgroundColor="components.elevation.level3.background"
      className="border-b-0"
      key={index}
      minHeight={34}
      removeStickyHeader={removeStickyHeader}
    >
      {header}
    </Header>
  )
}

const TableOutline = ({
  columns,
  variant,
  children,
}: {
  columns: HeaderColumn[]
  children: ReactNode
  variant?: PortfolioTableTraitTableVariant
}) => {
  const headers = useMemo(
    () =>
      columns.map((column, index) => (
        <TableHeaderText key={index}>{column.header}</TableHeaderText>
      )),
    [columns],
  )
  const minWidths = useMemo(
    () => columns.map(column => column.minWidth),
    [columns],
  )
  const maxWidths = useMemo(
    () => columns.map(column => column.maxWidth),
    [columns],
  )
  return (
    <Table
      headers={headers}
      maxColumnWidths={maxWidths}
      minColumnWidths={minWidths}
      removeStickyHeader={variant === "compact"}
      renderHeader={TableHeader}
    >
      {children}
    </Table>
  )
}

const TableSkeleton = ({
  variant = "default",
}: {
  variant?: PortfolioTableTraitTableVariant
}) => {
  const columns = useTraitTableColumns(variant)
  const isCompactVariant = variant === "compact"
  const displayCount = isCompactVariant
    ? MAX_SKELETON_TRAITS_TO_DISPLAY_COUNT
    : MAX_TRAITS_TO_DISPLAY_COUNT

  return (
    <TableOutline columns={columns} variant={variant}>
      {range(displayCount).map(index => (
        <Table.Row key={index}>
          <StyledTraitTableCell $variant={variant}>
            <PortfolioTableTraitCell.Skeleton />
          </StyledTraitTableCell>
          {range(columns.length - 1).map(index => (
            <StyledTraitTableCell
              $variant={variant}
              key={`default-skeleton-table-cell-${index}`}
            >
              <Skeleton.Line className="w-full" />
            </StyledTraitTableCell>
          ))}
        </Table.Row>
      ))}
      {!isCompactVariant && (
        <Block paddingLeft="16px">
          <PaginationArrowsSkeleton />
        </Block>
      )}
    </TableOutline>
  )
}

export const PortfolioTableTraitTable = Object.assign(
  PortfolioTableTraitTableBase,
  {
    Skeleton: TableSkeleton,
  },
)

const StyledTraitTableCell = styled(StyledTableCell)<{
  $variant: PortfolioTableTraitTableVariant
}>`
  ${variant({
    prop: "$variant",
    variants: {
      default: {
        minHeight: "57.5px",
      },
      compact: {
        minHeight: "54px",
      },
    },
  })}
`
