/**
 * @generated SignedSource<<0979ed7d5850b52cf89fa27cfbb6589d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TraitDisplayType = "AUTHOR" | "BOOST_NUMBER" | "BOOST_PERCENTAGE" | "DATE" | "NONE" | "NUMBER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useTraitValue_trait$data = {
  readonly dateValue: any | null;
  readonly displayType: TraitDisplayType | null;
  readonly floatValue: number | null;
  readonly intValue: string | null;
  readonly maxValue: number | null;
  readonly traitCount: number | null;
  readonly traitType: string;
  readonly value: string | null;
  readonly " $fragmentType": "useTraitValue_trait";
};
export type useTraitValue_trait$key = {
  readonly " $data"?: useTraitValue_trait$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTraitValue_trait">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTraitValue_trait",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traitType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "traitCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floatValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "intValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dateValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maxValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "TraitType",
  "abstractKey": null
};

(node as any).hash = "9b0d74dd66448731f05f8422a8bacc34";

export default node;
