import { useState } from "react"
import { MAX_TRAITS_TO_DISPLAY_COUNT } from "@/features/account/components/PortfolioTable/constants"

export const usePaginationArrows = () => {
  const [currentPage, setCurrentPage] = useState(1)

  const getStartIndex = (pageNumber: number) =>
    (pageNumber - 1) * MAX_TRAITS_TO_DISPLAY_COUNT

  return {
    currentPage,
    setCurrentPage,
    getStartIndex,
  }
}
