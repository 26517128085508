import React from "react"
import { Text, FlexColumn } from "@opensea/ui-kit"
import { graphql, useFragment } from "react-relay"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Skeleton } from "@opensea/ui-kit"
import { useTraitValue } from "@/features/account/components/PortfolioTable/hooks/useTraitValue"
import { PortfolioTableTraitCell_collection$key } from "@/lib/graphql/__generated__/PortfolioTableTraitCell_collection.graphql"
import { PortfolioTableTraitCell_trait$key } from "@/lib/graphql/__generated__/PortfolioTableTraitCell_trait.graphql"
import { snakeCaseToCapitalCase } from "@/lib/helpers/stringUtils"

type Props = {
  trait: PortfolioTableTraitCell_trait$key
  collection: PortfolioTableTraitCell_collection$key
}

const PortfolioTableTraitCellBase = ({
  trait: traitKey,
  collection: collectionKey,
}: Props) => {
  const trait = useFragment(
    graphql`
      fragment PortfolioTableTraitCell_trait on TraitType {
        ...useTraitValue_trait
      }
    `,
    traitKey,
  )

  const collection = useFragment(
    graphql`
      fragment PortfolioTableTraitCell_collection on CollectionType {
        ...useTraitValue_collection
      }
    `,
    collectionKey,
  )

  const { traitType, displayValue, displayPercentage, collectionTraitUrl } =
    useTraitValue(trait, collection)

  return (
    <>
      <FlexColumn>
        <Text.Body className="text-secondary" size="tiny">
          {snakeCaseToCapitalCase(traitType)}
        </Text.Body>
      </FlexColumn>
      <Block>
        <Text.Body asChild size="small">
          {collectionTraitUrl ? (
            <Link href={collectionTraitUrl} variant="subtle">
              {displayValue}
            </Link>
          ) : (
            <span>{displayValue}</span>
          )}
        </Text.Body>

        {displayPercentage && (
          <Text.Body className="text-secondary" size="small">
            {" "}
            {displayPercentage}
          </Text.Body>
        )}
      </Block>
    </>
  )
}

const CellSkeleton = () => (
  <>
    <Skeleton.Line className="mb-1 h-4 w-1/2" />
    <Skeleton.Line className="h-[18px] w-[65%]" />
  </>
)

export const PortfolioTableTraitCell = Object.assign(
  PortfolioTableTraitCellBase,
  {
    Skeleton: CellSkeleton,
  },
)
