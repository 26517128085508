import { Breakpoint } from "@opensea/ui-kit"
import { VirtualizedTableCellProps } from "@/design-system/VirtualizedTable/components/VirtualizedTableCell.react"
import { PortfolioColumn } from "@/features/account/components/PortfolioTable/types"

export const DESKTOP_BREAKPOINT: Breakpoint = "md"

export const COLLAPSED_ROW_HEIGHT = 56
export const EXPANDED_ROW_HEIGHT = 490
export const ITEM_HEIGHT_ESTIMATE = {
  min: COLLAPSED_ROW_HEIGHT,
  max: COLLAPSED_ROW_HEIGHT + EXPANDED_ROW_HEIGHT,
}

export const MAX_TRAITS_TO_DISPLAY_COUNT = 5

// Minus 2, to avoid shrinking the expanded row after loading all traits.
export const MAX_SKELETON_TRAITS_TO_DISPLAY_COUNT =
  MAX_TRAITS_TO_DISPLAY_COUNT - 2

export const MAX_COMPACT_TABLE_TRAITS_TO_DISPLAY_COUNT =
  MAX_TRAITS_TO_DISPLAY_COUNT - 2

// Plus 1, so we can tell if there are more traits.
export const MAX_TRAITS_TO_LOAD_COUNT = MAX_TRAITS_TO_DISPLAY_COUNT + 1

export const MAX_SKELETON_LISTINGS_TO_DISPLAY_COUNT = 2

export const CHECKBOX_PADDING = 12

export const COLUMN_CELL_PROPS: Record<
  PortfolioColumn,
  Partial<VirtualizedTableCellProps>
> = {
  item: {
    flexGrow: 2,
    paddingLeft: {
      _: 0,
      [DESKTOP_BREAKPOINT]: CHECKBOX_PADDING,
    },
    stickyLeft: 0,
    // Includes the checkbox and margin between the checkbox and the item name.
    width: "200px",
  },
  listingPrice: {
    flexGrow: 1,
    justifyContent: { _: "flex-end", [DESKTOP_BREAKPOINT]: "flex-start" },
    width: "100px",
  },
  bestOffer: {
    flexGrow: 1,
    justifyContent: { _: "flex-end", [DESKTOP_BREAKPOINT]: "flex-start" },
    width: "100px",
  },
  floorPrice: {
    flexGrow: 1,
    justifyContent: { _: "flex-end", [DESKTOP_BREAKPOINT]: "flex-start" },
    width: "100px",
  },
  difference: {
    flexGrow: 1,
    justifyContent: { _: "flex-end", [DESKTOP_BREAKPOINT]: "flex-start" },
    width: "100px",
  },
  cost: {
    flexGrow: 1,
    justifyContent: { _: "flex-end", [DESKTOP_BREAKPOINT]: "flex-start" },
    width: "100px",
  },
  options: {
    width: "144px",
  },
}
