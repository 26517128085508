/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import { Icon, IconProps, Text } from "@opensea/ui-kit"
import { ErrorBoundary } from "@sentry/react"
import { format } from "date-fns"
import { useFragment } from "react-relay"
import styled from "styled-components"
import { ExternalLink } from "@/components/common/ExternalLink"
import { LiveTimestamp } from "@/components/common/LiveTimestamp.react"
import { DATETIME_FORMAT_STRING } from "@/constants/datetime"
import { Block } from "@/design-system/Block"
import { Tooltip, TOOLTIP_PLACEMENT } from "@/design-system/Tooltip"
import { useTranslate } from "@/hooks/useTranslate"
import { EventTimestamp_data$key } from "@/lib/graphql/__generated__/EventTimestamp_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { dateFromISO8601, useFromNowShort } from "@/lib/helpers/datetime"
import { captureNoncriticalError } from "@/lib/sentry"

type Props = {
  className?: string
  data: EventTimestamp_data$key
  fontWeight?: React.CSSProperties["fontWeight"]
  subtle?: boolean
  showIcon?: boolean
  overrides?: {
    Icon?: Partial<IconProps>
  }
}

const InnerEventTimestamp = ({
  className,
  data: dataKey,
  fontWeight,
  subtle = false,
  showIcon = true,
  overrides = {},
}: Props) => {
  const t = useTranslate("components")
  const { eventTimestamp, transaction } = useFragment(
    graphql`
      fragment EventTimestamp_data on AssetEventType {
        eventTimestamp
        transaction {
          blockExplorerLink
        }
      }
    `,
    dataKey,
  )

  const renderFromNow = useFromNowShort()

  const liveTimestamp = (
    <LiveTimestamp
      renderFormattedTimestamp={() =>
        renderFromNow(dateFromISO8601(eventTimestamp))
      }
    />
  )

  const liveDetailedTimestamp = format(
    dateFromISO8601(eventTimestamp),
    DATETIME_FORMAT_STRING,
  )

  return (
    <DivContainer
      className={className}
      data-testid="EventTimestamp"
      fontWeight={fontWeight}
      subtle={subtle}
    >
      <Tooltip
        content={liveDetailedTimestamp}
        interactive
        placement={TOOLTIP_PLACEMENT.TOP}
      >
        {transaction ? (
          <ExternalLink
            className="EventTimestamp--link"
            href={transaction.blockExplorerLink}
            title={t(
              "eventTimestamp.blockExplorerLink.title",
              "Opens in a new window",
            )}
            onClick={event => {
              // Prevent onClick actions of ancestors if event timestamp is clicked
              event.stopPropagation()
            }}
          >
            {liveTimestamp}{" "}
            {showIcon && (
              <Block
                display="inline-block"
                lineHeight="0"
                marginBottom="2px"
                verticalAlign="middle"
              >
                <Icon
                  aria-hidden
                  className="EventTimestamp--link-icon"
                  value="open_in_new"
                  {...overrides.Icon}
                />
              </Block>
            )}
          </ExternalLink>
        ) : (
          <span>{liveTimestamp}</span>
        )}
      </Tooltip>
    </DivContainer>
  )
}

export const EventTimestamp = (props: Props) => {
  const t = useTranslate("components")

  return (
    <ErrorBoundary
      fallback={({ error }) => {
        captureNoncriticalError(error, { extras: { relayKey: props.data } })
        return <Text.Body>{t("eventTimestamp.errorText", "Error")}</Text.Body>
      }}
    >
      <InnerEventTimestamp {...props} />
    </ErrorBoundary>
  )
}

const DivContainer = styled(Block)<{ subtle: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  .EventTimestamp--link {
    color: ${props =>
      props.subtle ? props.theme.colors.text.secondary : undefined};
  }
`
