/**
 * @generated SignedSource<<0d2a2a7442cc642697606130a08e0138>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ItemCell_data$data = {
  readonly __typename: string;
  readonly assetQuantities?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly asset: {
          readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset">;
        };
        readonly relayId: string;
      } | null;
    } | null>;
  };
  readonly displayName: string;
  readonly " $fragmentSpreads": FragmentRefs<"AssetMedia_asset" | "PortfolioTableItemCellTooltip_item" | "item_url">;
  readonly " $fragmentType": "ItemCell_data";
};
export type ItemCell_data$key = {
  readonly " $data"?: ItemCell_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCell_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AssetMedia_asset"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemCell_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "item_url",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "asset_url",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetContractType",
                  "kind": "LinkedField",
                  "name": "assetContract",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "address",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tokenId",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "bundle_url",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortfolioTableItemCellTooltip_item"
    },
    {
      "kind": "InlineFragment",
      "selections": (v2/*: any*/),
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 30
            }
          ],
          "concreteType": "AssetQuantityTypeConnection",
          "kind": "LinkedField",
          "name": "assetQuantities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetQuantityTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetQuantityType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssetType",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": (v2/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "relayId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "assetQuantities(first:30)"
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "1ffe48450147b94712a2b8f7fddb87e6";

export default node;
