/**
 * @generated SignedSource<<21c6a45122178210b671ecff0ea3cdf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioTableTraitCell_trait$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useTraitValue_trait">;
  readonly " $fragmentType": "PortfolioTableTraitCell_trait";
};
export type PortfolioTableTraitCell_trait$key = {
  readonly " $data"?: PortfolioTableTraitCell_trait$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioTableTraitCell_trait">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioTableTraitCell_trait",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTraitValue_trait"
    }
  ],
  "type": "TraitType",
  "abstractKey": null
};

(node as any).hash = "469a3d0e67340a1f0b27ed6abfdd0bce";

export default node;
